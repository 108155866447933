@import 'abstracts';

app-filter-buttons {
  @include media-breakpoint-down(md) {
    .w-max-content,
    .p-button {
      width: 100%;
    }
  }
}
